import { makeAutoObservable, configure } from 'mobx';
import userFactory from './userFactory';

configure({
    enforceActions: 'never',
});

export default () => {
    const store = {
        userSettings: {
            data: {
                groupings: [],
                group: {
                    selected: {},
                },
                compound: {},
                groups: [],
                activeGroup: {
                    selected: undefined,
                },
            },
        },
    };

    const factory = userFactory({ store });
    const { userSettings } = factory;

    Object.assign(store, {
        userSettings: Object.assign(userSettings, store.userSettings),
    });
    return makeAutoObservable(store);
};
