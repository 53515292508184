import { useApiContext } from '../apiclient';

export default ({ store }) => {
    const apiClient = useApiContext();

    const currentConfig = {
        clear: async () => {
            store.currentConfig.filterStatus = 'NOTFOUND';
            store.currentConfig.data = {
                configs: [],
            };
            store.currentConfig.configsLoading = false;
            store.currentConfig.activeConfig = null;
        },
        async getLoadConfigurations() {
            try {
                const configs = await apiClient.getUnknownLoadConfigurations(
                    store.currentConfig.filterStatus
                );

                store.currentConfig.data.configs = configs?.data;
                store.currentConfig.configsLoading = true;
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            }
        },
        async getCountriesWithLegislations() {
            const countries = await apiClient.getCountries();
            const legislations = countries?.data.country.map((element) => {
                return { label: element.fullName, value: element.name };
            });
            store.currentConfig.legislations = legislations;
        },
        async approve(loadID) {
            try {
                await apiClient.updateLoadConfigurationStatus(
                    loadID,
                    'APPROVED'
                );
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await currentConfig.getLoadConfigurations(
                    store.currentConfig.filterStatus
                );
            }
        },
        async reject(loadID) {
            try {
                await apiClient.updateLoadConfigurationStatus(
                    loadID,
                    'REJECTED'
                );
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await currentConfig.getLoadConfigurations(
                    store.currentConfig.filterStatus
                );
            }
        },
        async init() {
            await store.currentConfig.getLoadConfigurations();
            await store.currentConfig.getCountriesWithLegislations();
        },
        setConfigStatus(status) {
            store.currentConfig.filterStatus = status;
            currentConfig.getLoadConfigurations(status);
        },
    };

    return {
        currentConfig,
    };
};
