import React, { createContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useAPIClient } from './useApiClient.hook';

const ApiContext = createContext(undefined);
export default observer(({ children }) => {
    const apiClient = useAPIClient();
    return (
        <ApiContext.Provider value={apiClient}>{children}</ApiContext.Provider>
    );
});

export const useApiContext = () => {
    const context = React.useContext(ApiContext);
    if (context === undefined) {
        throw new Error(
            'useApiContext must be used within a ApiContext.Provider'
        );
    }
    return context;
};
