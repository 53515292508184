import { makeAutoObservable } from 'mobx';
import loadConfigurationFactory from './loadConfigurationFactory';

export default () => {
    const store = {
        currentConfig: {
            configsLoading: false,
            activeConfig: null,
            filterStatus: 'NOTFOUND',
            data: {
                configs: [],
            },
            legislations: {},
        },
    };

    const factory = loadConfigurationFactory({ store });
    const { currentConfig } = factory;

    Object.assign(store, {
        currentConfig: Object.assign(currentConfig, store.currentConfig),
    });
    return makeAutoObservable(store);
};
