// from https://github.com/Poyoman39/react-key-from-object/blob/main/src/useKeyGen.js
import { useMemo, useRef } from 'react';
import ReactKeyGen, {
    Primitive,
    PrimitiveWithObject,
    ReactKeyGenProps,
} from './keyGen.helper';

const useKeyGen = <T extends PrimitiveWithObject>(keyBaseName = '') => {
    const getKeyCounterRef = useRef(0);

    getKeyCounterRef.current = 0;

    const keyGen = useMemo(() => {
        const keyGenObject: ReactKeyGenProps = {
            primitiveToKey: (value: Primitive) => {
                getKeyCounterRef.current += 1;
                return `${keyBaseName ?? `${keyBaseName}_`}${(
                    value || 0
                ).toString()}_${getKeyCounterRef.current.toString()}`;
            },
        };
        return ReactKeyGen<T>({
            ...keyGenObject,
            keyBaseName: !keyBaseName ? undefined : keyBaseName,
        });
    }, []);

    return keyGen;
};

export default useKeyGen;
