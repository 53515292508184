import { FlagsProvider } from 'react-feature-flags';
import { observer } from 'mobx-react-lite';
import { useKeycloak } from '@react-keycloak/web';
import featureFlags from '../stores/featureFlagsStore';

export default observer(({ children }) => {
    const { keycloak } = useKeycloak();
    const createFlags = () => {
        return featureFlags(keycloak);
    };

    const flags = createFlags();
    return <FlagsProvider value={flags}>{children}</FlagsProvider>;
});
