import { cilWarning } from '@coreui/icons';

type ComparisonOption = {
    greaterThan?: number;
    lowerThan?: number;
    equals?: number | string | boolean;
};

interface WarningIconConfigItem {
    icon: string[];
    color: 'warning' | 'alert';
    conditions: {
        [k: string]: ComparisonOption;
    };
}

interface AppConfigInterface {
    apiGateway: {
        URL: string;
        enabled: boolean;
        paramManagerPrefix: string;
        metricsPrefix: string;
    };
    apiDirect: {
        paramManager: string;
        metrics: string;
    };
    auth: {
        enabled: boolean;
        realm: string;
        url: string;
        'ssl-required': string;
        resource: string;
        'public-client': boolean;
        clientId: string;
    };
    loadConfig: {
        keyMetric: string;
        sellFlagKeyMetric: string;
        sellFlagMinimumLoad: number;
        layout: 'minimal' | 'compact';
    };
    warningIconConfig: Array<WarningIconConfigItem>;
}

const envOrDefault = <T extends boolean | string>(
    envVar: string,
    orElse: T
): T => {
    if (!window.__env) return orElse;
    const envValue = window.__env[envVar];
    return (envValue || orElse) as T;
};

export const AppConfig: AppConfigInterface = {
    apiGateway: {
        URL: envOrDefault('API_GATEWAY', 'http://localhost:8081'),
        enabled: envOrDefault('GATEWAY_ENABLED', false),
        paramManagerPrefix: '/parameter-manager',
        metricsPrefix: '/metrics',
    },
    apiDirect: {
        paramManager: envOrDefault('API_PARAM', 'http://localhost:8081'),
        metrics: envOrDefault('API_METRICS', 'http://localhost:8084'),
    },
    auth: {
        enabled: envOrDefault('AUTH_ENABLED', false),
        realm: envOrDefault('AUTH_REALM', 'floconcepts'),
        url: envOrDefault('AUTH_URL', 'http://localhost:8090/auth'),
        'ssl-required': envOrDefault('AUTH_SSL_REQUIRED', 'external'),
        resource: envOrDefault('AUTH_RESOURCE', 'webfrontend'),
        'public-client': envOrDefault('AUTH_PUBLIC_CLIENT', true),
        clientId: envOrDefault('AUTH_CLIENT_ID', 'webfrontend'),
    },
    loadConfig: {
        keyMetric: 'routeUtilisation',
        sellFlagKeyMetric: 'routeUtilisation',
        sellFlagMinimumLoad: 60,
        layout: 'minimal',
    },
    warningIconConfig: [
        {
            icon: cilWarning,
            color: 'warning',
            conditions: {
                isOverWeight: {
                    equals: true,
                },
            },
        },
    ],
};
