// from https://github.com/streamich/react-use/blob/master/src/useFirstMountState.ts
// as whole react-use is deprecated
import { useEffect, useRef } from 'react';

export function useFirstMountState(): boolean {
    const isFirst = useRef(true);

    useEffect(() => {
        isFirst.current = false;
    }, []);

    return isFirst.current;
}
