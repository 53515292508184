import { useState, useEffect } from 'react';

type StoredValue<T> = T | undefined;

function useLocalStorage<T>(
    key: string,
    initialValue?: T
): [StoredValue<T>, (value: T) => void] {
    const [storedValue, setStoredValue] = useState<StoredValue<T>>(() => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(`Error retrieving localStorage key '${key}':`, error);
            return initialValue;
        }
    });

    useEffect(() => {
        try {
            if (storedValue === undefined) {
                localStorage.removeItem(key);
            } else {
                localStorage.setItem(key, JSON.stringify(storedValue));
            }
        } catch (error) {
            console.error(`Error storing localStorage key '${key}':`, error);
        }
    }, [key, storedValue]);

    const setValue = (value: T) => {
        setStoredValue(value);
    };

    return [storedValue, setValue];
}

export default useLocalStorage;
