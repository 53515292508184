/* eslint-disable no-param-reassign */
import { clearIntervalAsync, setIntervalAsync } from 'set-interval-async';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { useApiContext } from '../apiclient';

export default ({ store }) => {
    const apiClient = useApiContext();

    const iAmDirtyNvdHack = (placeName) => {
        return placeName === 'NVD Baldonnel' || placeName === 'NVD Kill'
            ? 'NVDBaldonnelKill'
            : placeName;
    };
    const getStoreLoad = (load) => {
        return store.currentSession.data.loads.find((el) => {
            return el.id === load.id;
        });
    };
    const setLoadOrdersParam = (load, param, value) => {
        store.currentSession.data.orders.forEach((el) => {
            if (
                _.findIndex(load.orders, (o) => {
                    return _.isMatch(o, el);
                }) > -1
            )
                el[param] = value;
        });
    };
    const removeLoadAssociations = ({ orders, load }) => {
        load.orders = load.orders.filter((el) => !orders.includes(el.id));

        store.currentSession.data.orders
            .filter((el) => orders.includes(el.id))
            .forEach((el) => {
                el.loadAssociations = el.loadAssociations.filter(
                    (l) => l !== load.id
                );
            });
    };
    const addLoadAssociations = ({ orders, load }) => {
        load.orders = load.orders?.concat(
            store.currentSession.data.orders.filter((el) =>
                orders.includes(el.id)
            )
        );

        store.currentSession.data.orders
            .filter((el) => orders.includes(el.id))
            .forEach((el) => {
                el.loadAssociations.push(load.id);
            });
    };

    const currentSession = {
        clear: async () => {
            store.currentSession.sessionID = null;
            store.currentSession.loading = {
                session_orders: false,
                session_solver_iterations: false,
                session_solver_proposed_loads: false,
                session_transporters: false,
            };
            store.currentSession.lastUpdate = {
                global: null,
                session_orders: null,
                session_solver_iterations: null,
                session_solver_proposed_loads: null,
                session_transporters: null,
                session_solver_sessions: null,
                session_solver_load_orders: null,
            };
            store.currentSession.data = {
                sessionState: null,
                loads: [],
                orders: [],
                transporters: [],
                transporterTypes: [],
            };
            if (store.currentSession.interval) {
                await clearIntervalAsync(store.currentSession.interval);
            }
            store.currentSession.interval = undefined;
            store.currentSession.activeLoad.load = null;
            store.currentSession.compoundResources = [];
            store.currentSession.tabKey = 'all';
            store.currentSession.filteredOrders = [];
            store.currentSession.filteredLoads = [];
            store.currentSession.activeLoad.alternativeTruckCheck.status =
                'NONE';
            store.currentSession.activeLoad.alternativeTruckCheck.expertLoad =
                '';
            store.currentSession.activeFilters = [];
            store.currentSession.loadMapOverlay = false;
        },
        async makeLoadActive(load) {
            // TODO: remove when all usage of this function is replaced with setActiveLoad from useSession
            store.currentSession.activeLoad.load = load;
            store.currentSession.activeLoad.alternativeTruckCheck.start.options =
                _.uniqBy(
                    load.orders.map((element) => {
                        return {
                            label: element.from,
                            value: element.from,
                        };
                    }),
                    'label'
                );
            store.currentSession.activeLoad.alternativeTruckCheck.transporter.selected =
                {
                    value: load.transporterType,
                    label: load.transporterType,
                };

            const filteredLegislation =
                store.currentSession.data.legislations.filter(
                    (l) => l.value === load.transporter_legislation
                )[0];
            store.currentSession.activeLoad.alternativeTruckCheck.legislation.selected =
                {
                    value: filteredLegislation.value,
                    label: filteredLegislation.label,
                };
            store.currentSession.activeLoad.alternativeTruckCheck.start.selected =
                {
                    value: load.tour_json.start,
                    label: load.tour_json.start,
                };

            if (
                store.currentSession.activeLoad.load.transporterConfiguration
                    .validity === 'CONFIRMED'
            ) {
                store.currentSession.activeLoad.alternativeTruckCheck.status =
                    'FOUND';
                store.currentSession.activeLoad.alternativeTruckCheck.expertLoad =
                    store.currentSession.activeLoad.load.transporterConfiguration.id;
            } else if (
                store.currentSession.activeLoad.load.transporterConfiguration
                    .validity !== 'CONFIRMED'
            ) {
                store.currentSession.activeLoad.alternativeTruckCheck.status =
                    'NOTFOUND';
                store.currentSession.activeLoad.alternativeTruckCheck.expertLoad =
                    '';
            }
        },
        makeLoadUnactive() {
            store.currentSession.activeLoad.load = null;
            store.currentSession.activeLoad.alternativeTruckCheck = {
                expertLoad: '',
                status: '',
                start: {
                    options: [],
                    selected: {},
                },
                transporter: {
                    selected: {},
                },
                legislation: {
                    selected: {},
                },
            };
        },
        async startIteration(continueSolver, multiIterations) {
            store.currentSession.multiIteration = multiIterations;
            try {
                await apiClient.startSolverRun(
                    store.currentSession.sessionID,
                    continueSolver,
                    multiIterations
                );
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await store.currentSession.getData();
            }
        },
        async checkLoadFitsTransporter(load, type, legislation) {
            try {
                const body = {
                    transporterType: type,
                    legislation,
                };
                const response = await apiClient.checkLoadConfigFitsTruck(
                    load,
                    body
                );
                store.currentSession.activeLoad.alternativeTruckCheck.status =
                    response?.data.status;
                store.currentSession.activeLoad.alternativeTruckCheck.expertLoad =
                    response?.data.expertLoad;
            } catch (err) {
                store.currentSession.activeLoad.alternativeTruckCheck.status =
                    'NONE';
                store.currentSession.activeLoad.alternativeTruckCheck.expertLoad =
                    '';
            } finally {
                await store.currentSession.getData();
            }
        },
        async changeConfigStatus({ loadID, status, approver }) {
            try {
                await apiClient.updateLoadConfigurationStatus(loadID, status);
                await apiClient.updateTransporterConfigProposal(
                    loadID,
                    status,
                    approver
                );
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await store.currentSession.getData();
            }
        },
        async updateLoadTransporter(
            load,
            type,
            legislation,
            expertLoad,
            startLocation
        ) {
            try {
                const details = {
                    transporterType: type,
                    legislation,
                    expertLoad,
                    startLocation,
                };
                await apiClient.updateLoadTransporter(load, details);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await store.currentSession.getData();
            }
        },
        async flipLoadSellFlag(loadID) {
            // TODO: this function and the api call are moved to useSession, remove
            try {
                await apiClient.flipLoadSellFlag(loadID);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            }
        },
        resetAlternativeTruckResult() {
            store.currentSession.activeLoad.alternativeTruckCheck.status =
                'NONE';
            store.currentSession.activeLoad.alternativeTruckCheck.expertLoad =
                '';
        },
        changeActiveFilters(value) {
            store.currentSession.activeFilters = value;
        },
        async getSessionTransporters() {
            const sessionTransporters = await apiClient.getSessionTransporters(
                store.currentSession.sessionID
            );
            store.currentSession.data.transporters = sessionTransporters?.data;
        },
        async getSolverSessionState() {
            const sessionState = await apiClient.getSolverSessionState(
                store.currentSession.sessionID
            );
            store.currentSession.data.sessionState = sessionState?.data;
        },
        async getSessionLoads() {
            const sessionLoads = await apiClient.getSessionLoads(
                store.currentSession.sessionID
            );
            // TODO: this is considered as a bad design
            //  (sorting should be either on backend or configurable on frontend
            //  doing only as this repo is thrown away candidate

            store.currentSession.data.loads = sessionLoads?.data;
        },
        async getSessionReport() {
            const sessionReport = await apiClient.getSessionReport(
                store.currentSession.sessionID
            );
            store.currentSession.data.sessionReport = sessionReport?.data;
        },
        async getSessionOrders() {
            const sessionOrders = await apiClient.getSessionOrders(
                store.currentSession.sessionID
            );
            store.currentSession.data.orders = sessionOrders?.data;
        },
        async getData() {
            try {
                const getIterations = await apiClient.getSolverSessionState(
                    store.currentSession.sessionID
                );

                // FIXME: we should not need metrics here, we should need iteration statuses
                // const iterationMetrics =
                //     await apiClient.getMultiSessionIterationMetrics();
                // store.currentSession.metrics = iterationMetrics?.data;

                const filteredIterations =
                    getIterations?.data?.iterations.filter(
                        (iteration) =>
                            iteration.status === 'READY' &&
                            !_.isEmpty(iteration.config)
                    );

                store.currentSession.multiIteration = filteredIterations > 0;

                const queries = [];

                const setTransporters = async () => {
                    await store.currentSession.getSessionTransporters();
                    store.currentSession.setMappedTransporters();
                };

                const setLoads = async () => {
                    await store.currentSession.getSessionLoads();
                    await store.currentSession.getSessionReport();
                    store.currentSession.setFilterLoads('all');
                };
                const setOrders = async () => {
                    store.currentSession.loading.session_orders = true;
                    await store.currentSession.getSessionOrders();
                    store.currentSession.setFilterOrders();
                    store.currentSession.loading.session_orders = false;
                };

                const setLoadOrders = async () => {
                    if (store.currentSession.manualLoad) {
                        store.currentSession.manualLoad = false;
                    } else {
                        store.currentSession.loading.session_orders = true;
                        await store.currentSession.getSessionOrders();
                        await store.currentSession.getSessionLoads();
                        store.currentSession.setFilterOrders();
                        store.currentSession.setFilterLoads('all');
                        store.currentSession.loading.session_orders = false;
                    }
                };

                if (!store.currentSession.multiIteration) {
                    const response = await apiClient.getSessionLastUpdate(
                        store.currentSession.sessionID
                    );

                    if (
                        response?.session_transporters !==
                        store.currentSession.lastUpdate.session_transporters
                    ) {
                        queries.push(setTransporters());
                    }

                    if (
                        response?.session_solver_iterations !==
                        store.currentSession.lastUpdate
                            .session_solver_iterations
                    ) {
                        queries.push(
                            store.currentSession.getSolverSessionState()
                        );
                    }

                    if (
                        response?.session_solver_proposed_loads !==
                        store.currentSession.lastUpdate
                            .session_solver_proposed_loads
                    ) {
                        queries.push(setLoads());
                    }

                    if (
                        response?.session_orders !==
                        store.currentSession.lastUpdate.session_orders
                    ) {
                        queries.push(setOrders());
                    }

                    if (
                        response?.session_solver_load_orders !==
                        store.currentSession.lastUpdate
                            .session_solver_load_orders
                    ) {
                        queries.push(setLoadOrders());
                    }

                    Promise.all(queries).then(() => {
                        store.currentSession.lastUpdate = response;
                    });
                }
            } catch (err) {
                store.currentSession.loading = {
                    session_orders: false,
                    session_solver_iterations: false,
                    session_solver_proposed_loads: false,
                    session_transporters: false,
                };
                // eslint-disable-next-line no-console
                console.error(err);
            }
        },
        async init() {
            const sessionTransporterTypes =
                await apiClient.getTransporterTypes();
            const transporterTypes = sessionTransporterTypes?.data?.map(
                (element) => {
                    return {
                        label: element.name,
                        value: element.name,
                        truckWeightCapacity: element.truckWeightCapacity,
                    };
                }
            );
            store.currentSession.data.transporterTypes = transporterTypes;

            const countries = await apiClient.getCountries();
            const legislations = countries?.data.country.map((element) => {
                return { label: element.fullName, value: element.name };
            });
            store.currentSession.data.legislations = legislations;

            const defaultValues = await apiClient.getSystemDefaultValues();
            const parsed = JSON.parse(defaultValues?.data.value);
            store.currentSession.data.defaultTransporter =
                parsed.transporterType;
            store.currentSession.data.defaultLegislation = parsed.legislation;

            // FIXME: we should not need metrics here, we should need iteration statuses
            // const iterationMetrics =
            // await apiClient.getMultiSessionIterationMetrics();
            // store.currentSession.metrics = iterationMetrics?.data;

            await store.currentSession.getData();
            store.currentSession.interval = setIntervalAsync(async () => {
                try {
                    await currentSession.getData();
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.error(err);
                }
            }, 10000);
        },
        async save() {
            try {
                await apiClient.saveSession(store.currentSession.sessionID);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await currentSession.getData();
            }
        },
        setFilters() {
            const { setMappedTransporters, setFilterOrders, setFilterLoads } =
                store.currentSession;

            setMappedTransporters();
            setFilterOrders();
            setFilterLoads(null);
        },

        async acceptLoad(load) {
            const { data, setFilters } = store.currentSession;
            const storeLoad = getStoreLoad(load);

            const freeT = data.transporters.find((el) => {
                return (
                    el.assignedLoad === '' &&
                    el.name === load.transporterConfiguration.transporterType &&
                    iAmDirtyNvdHack(el.originLocation?.name) ===
                        iAmDirtyNvdHack(storeLoad.startPoint?.name)
                );
            });

            if (freeT) {
                freeT.assignedLoad = storeLoad.id;
                storeLoad.transporterResource = freeT;
            }

            // it does not make sense since it is not assigned anywhere...
            data.loads
                .filter((el) => {
                    return el.status === 'PROPOSED' && el.id !== storeLoad.id;
                })
                .forEach((loopLoad) => {
                    const bMap = _.keyBy(storeLoad.orders, 'id');
                    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
                    const [same, notSame] = _.partition(
                        loopLoad.orders,
                        ({ id }) => id in bMap
                    );

                    loopLoad.orders = notSame;
                });

            setLoadOrdersParam(load, 'loadAssociations', [storeLoad.id]);
            setLoadOrdersParam(load, 'state', 'ACCEPTED');

            storeLoad.status = 'ACCEPTED';

            setFilters();
            try {
                await apiClient.setLoadStatus(storeLoad.id, 'ACCEPTED');
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await currentSession.getData();
            }
        },
        async rejectLoad(load) {
            const { data, setFilters } = store.currentSession;
            const storeLoad = getStoreLoad(load);
            const loadTransporter = data.transporters.find(
                (el) => el.assignedLoad === load.id
            );
            loadTransporter.assignedLoad = '';
            setLoadOrdersParam(load, 'state', undefined);
            storeLoad.transporterResource = {};
            storeLoad.status = 'PROPOSED';
            setFilters();
            try {
                await apiClient.setLoadStatus(storeLoad.id, 'PROPOSED');
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await currentSession.getData();
            }
        },
        async removeLoad(id) {
            const { data, setFilters } = store.currentSession;
            const storeLoad = getStoreLoad({ id });

            removeLoadAssociations({
                orders: storeLoad.orders.map((el) => el.id),
                load: storeLoad,
            });
            setLoadOrdersParam(storeLoad, 'state', undefined);
            data.loads = data.loads.filter((el) => {
                return el.id !== id;
            });

            setFilters();

            try {
                await apiClient.removeLoad(id);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await currentSession.getData();
            }
        },

        async createManualLoad() {
            try {
                const loadID = uuidv4();
                const loadSetup = {
                    sessionID: store.currentSession.sessionID,
                    iterationID: loadID, // TODO: wtf?
                };
                const load = await apiClient.createManualLoad(
                    loadID,
                    loadSetup
                );
                store.currentSession.activeLoad.load = load?.data;
                store.currentSession.manualLoad = true;
                store.currentSession.manualLoadID = loadID;
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await currentSession.getData();
            }
        },
        async rollbackManualLoad() {
            // TODO: functionality will be moved partially to context / apiCalls
            try {
                const loadID = store.currentSession.manualLoadID;
                await apiClient.removeLoad(loadID);
                store.currentSession.activeLoad.load = null;
                store.currentSession.manualLoad = false;
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await currentSession.getData();
            }
        },
        async addOrdersToLoad(orders) {
            const { setFilters } = store.currentSession;
            if (store.currentSession.activeLoad?.load?.id !== undefined) {
                let load = getStoreLoad({
                    id: store.currentSession.activeLoad.load.id,
                });
                // TODO: whole "new load" process should be taken away tyo new process
                // lines below is a hack for "i want a load that is not created yet"
                if (!load) {
                    load = {
                        id: store.currentSession.activeLoad.load.id,
                        orders: [],
                        integrity: 'DIRTY',
                    };
                }
                // TODO: that is inconsistent, because we add load in background (empty) for no reason other than insert into box
                // proper process is to create backend endpoint PUT /load that accepts session_id and orders
                if (Array.isArray(load?.orders)) {
                    addLoadAssociations({ orders, load });
                }
                load.integrity = 'DIRTY';

                setFilters();
                try {
                    await apiClient.modifyLoadAssociationForMultipleOrders(
                        store.currentSession.activeLoad.load.id,
                        orders.map((o) => {
                            return { orderID: o, operation: 'ADD' };
                        })
                    );
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.error(err);
                } finally {
                    await currentSession.getData();
                }
            } else {
                await currentSession.getData();
            }
        },
        async removeOrdersFromLoad(orders) {
            // TODO: remove this function since it was moved to useSession
            const { setFilters } = store.currentSession;
            const storeLoad = getStoreLoad({
                id: store.currentSession.activeLoad.load.id,
            });

            removeLoadAssociations({ orders, load: storeLoad });
            storeLoad.integrity = 'DIRTY';
            setFilters();
            try {
                await apiClient.modifyLoadAssociationForMultipleOrders(
                    store.currentSession.activeLoad.load.id,
                    orders.map((o) => {
                        return { orderID: o, operation: 'DELETE' };
                    })
                );
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await currentSession.getData();
            }
        },
        /** TODO: function is being replaced in useApiCalls */
        async setOrderPriority(order, priority) {
            try {
                await apiClient.setHighPriority(order, priority);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await currentSession.getData();
            }
        },
        async createLoadAndAssignOrders(orders) {
            await store.currentSession.createManualLoad(orders);
            await store.currentSession.addOrdersToLoad(orders);
            if (store.currentSession.activeLoad.load !== null) {
                await store.currentSession.checkLoadFitsTransporter(
                    store.currentSession.activeLoad.load.id,
                    store.currentSession.data.defaultTransporter.value,
                    store.currentSession.data.defaultLegislation.value
                );
            }
        },
        preselectModalFields(orders) {
            store.currentSession.manualOrders =
                store.currentSession.data.orders.filter((o) => {
                    if (orders.includes(o.id)) {
                        return o.id;
                    }
                });
            store.currentSession.activeLoad.alternativeTruckCheck.start.options =
                _.uniqBy(
                    store.currentSession.manualOrders.map((element) => {
                        return {
                            label: element.from,
                            value: element.from,
                        };
                    }),
                    'label'
                );
            store.currentSession.selectTransporter(
                store.currentSession.data.defaultTransporter
            );
            store.currentSession.selectLegislation(
                store.currentSession.data.defaultLegislation
            );
            store.currentSession.selectStartLocation(
                store.currentSession.activeLoad.alternativeTruckCheck.start
                    .options[0]
            );
        },
        selectExpertLoad(value) {
            store.currentSession.activeLoad.alternativeTruckCheck.expertLoad =
                value;
        },
        selectTransporter(value) {
            store.currentSession.activeLoad.alternativeTruckCheck.transporter.selected =
                value ?? '';
        },
        selectLegislation(value) {
            store.currentSession.activeLoad.alternativeTruckCheck.legislation.selected =
                value;
        },
        selectStartLocation(value) {
            store.currentSession.activeLoad.alternativeTruckCheck.start.selected =
                value;
        },
        setMappedTransporters() {
            const compounds = [];
            store.currentSession.data.transporters.forEach((transporter) => {
                if (
                    !compounds.find(
                        (compound) =>
                            compound.name === transporter.originLocation.name
                    )
                ) {
                    const cp = transporter.originLocation;
                    cp.resources = [
                        {
                            id: transporter.id,
                            name: transporter.name,
                            amount: 1,
                            used: 0,
                            sent: 0,
                        },
                    ];
                    compounds.push(cp);
                } else {
                    const cpx = compounds.find(
                        (compound) =>
                            compound.name === transporter.originLocation.name
                    );
                    const trx = cpx.resources.find(
                        (tr) => tr.name === transporter.name
                    );
                    if (
                        trx &&
                        ['SENT', 'OUTBOX'].includes(transporter.status)
                    ) {
                        trx.amount += 1;
                        trx.sent += 1;
                    } else if (trx) {
                        trx.amount += 1;
                    } else {
                        cpx.resources.push({
                            id: transporter.id,
                            name: transporter.name,
                            amount: 1,
                            used: 0,
                            sent: 0,
                        });
                    }
                }
            });
            compounds.forEach((compound) => {
                compound.resources.forEach((resource) => {
                    resource.used =
                        store.currentSession.data.transporters.filter(
                            (transporter) =>
                                transporter.originLocation.name ===
                                    compound.name &&
                                transporter.assignedLoad !== '' &&
                                !['SENT', 'OUTBOX'].includes(
                                    transporter.status
                                ) &&
                                transporter.name === resource.name
                        ).length;
                    resource.sent =
                        store.currentSession.data.transporters.filter(
                            (transporter) =>
                                transporter.originLocation.name ===
                                    compound.name &&
                                ['SENT', 'OUTBOX'].includes(
                                    transporter.status
                                ) &&
                                transporter.name === resource.name
                        ).length;
                });
            });

            store.currentSession.compoundResources = compounds;
        },
        setFilterOrders() {
            const { data } = store.currentSession;
            const proposedLoads = data.loads
                .filter((load) => load.status === 'PROPOSED')
                .map((load) => load.id);
            const acceptedLoads = data.loads
                .filter((load) => load.status === 'ACCEPTED')
                .map((load) => load.id);
            const submittedLoads = data.loads
                .filter(
                    (load) => load.status === 'SENT' || load.status === 'OUTBOX'
                )
                .map((load) => load.id);

            switch (store.currentSession.tabKey) {
                case 'unplanned':
                    store.currentSession.filteredOrders = data.orders.filter(
                        (order) => order.loadAssociations.length === 0
                    );
                    break;
                case 'proposed':
                    store.currentSession.filteredOrders = data.orders.filter(
                        (order) =>
                            order.loadAssociations.some((r) =>
                                proposedLoads.includes(r)
                            )
                    );
                    break;
                case 'accepted':
                    store.currentSession.filteredOrders = data.orders.filter(
                        (order) =>
                            order.loadAssociations.some((r) =>
                                acceptedLoads.includes(r)
                            )
                    );
                    break;
                case 'submitted':
                    store.currentSession.filteredOrders = data.orders.filter(
                        (order) =>
                            order.loadAssociations.some((r) =>
                                submittedLoads.includes(r)
                            )
                    );
                    break;
                case 'priority':
                    store.currentSession.filteredOrders = data.orders.filter(
                        (order) =>
                            order.priority === '9' ||
                            order.priority === '3' ||
                            order.priority === '2' ||
                            order.priority === '1' ||
                            order.priority === '0'
                    );
                    break;
                default:
                    store.currentSession.filteredOrders = data.orders;
                    break;
            }
        },
        setTabKey(tabKey) {
            store.currentSession.tabKey = tabKey;
            currentSession.setFilterOrders();
        },

        // TODO: refactored version of this function is currently in SessionContainer. Remove the one below after refactoring all elements that use it
        setFilterLoads(type, args) {
            const sortLoadsReducer = ({ loads }) => {
                const statusMap = {};
                statusMap.PROPSED = 0;
                statusMap.ACCEPTED = 1;
                statusMap.OUTBOX = 2;
                statusMap.SENT = 3;

                return loads
                    ?.sort((a, b) => {
                        return statusMap[a.status || -1] - [b.status || -1];
                    })
                    ?.sort((a, b) => {
                        const latestA = _.maxBy(a?.metrics_json, 'version');
                        const latestB = _.maxBy(b?.metrics_json, 'version');
                        if (
                            !Array.isArray(latestA?.metrics) ||
                            !Array.isArray(latestB?.metrics)
                        ) {
                            return 0;
                        }

                        const aU = latestA?.metrics?.find(
                            (e) => e.name === 'truckUtilisation'
                        );
                        const bU = latestB?.metrics?.find(
                            (e) => e.name === 'truckUtilisation'
                        );
                        return aU === undefined || bU === undefined
                            ? 0
                            : bU.value - aU.value;
                    });
            };

            switch (type) {
                case 'havingOrder':
                    store.currentSession.filteredLoads = sortLoadsReducer({
                        loads: store.currentSession.data.loads.filter((el) =>
                            args.order?.loadAssociations?.includes(el.id)
                        ),
                    });
                    break;
                case 'all':
                    store.currentSession.filteredLoads = sortLoadsReducer({
                        loads: store.currentSession.data.loads,
                    });
                    break;
                case 'search':
                    store.currentSession.filteredLoads = sortLoadsReducer({
                        loads: args.loads,
                    });
                    break;
                default:
                    store.currentSession.filteredLoads = sortLoadsReducer({
                        loads: store.currentSession.data.loads,
                    });
                    break;
            }
        },
        async pickIteration(sessionID, iterationsList) {
            try {
                await apiClient.pickIteration(sessionID, iterationsList);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await store.refreshSessions();
            }
        },
    };

    const globalSessions = {
        clear: async () => {
            store.globalSessions.data = {
                places: [],
                types: [],
                report: {},
                resources: [],
                rules: [],
                filteredRules: [],
                zones: [],
                orders: [],
                hydratedOrders: [],
                hydratedOrdersCount: 0,
                filteredOrders: [],
                liveTransporters: [],
            };
            store.globalSessions.activeFilters = [];
        },
        changeActiveFilters(value) {
            store.globalSessions.activeFilters = value;
        },
        clearResources() {
            store.globalSessions.data.resources = [];
        },
        addExcelResources(resources) {
            const extraFields = [
                'startLocationName',
                'startLocationCode',
                'endLocationName',
                'endLocationCode',
                'startTime',
                'endTime',
            ];

            store.globalSessions.clearResources();
            resources.forEach((resource) => {
                extraFields.forEach((field) => {
                    if (resource[field] === undefined) {
                        resource[field] = '';
                    }
                });
            });

            store.globalSessions.data.resources = resources;
        },
        addConfigToResources(type) {
            const newResources = store.globalSessions.data.resources;
            if (
                newResources.filter((transporter) => {
                    return (
                        type.fleetName === transporter.fleetName &&
                        type.placeCode === transporter.placeCode
                    );
                }).length > 0
            ) {
                newResources.find((transporter) => {
                    return (
                        type.fleetName === transporter.fleetName &&
                        type.placeCode === transporter.placeCode
                    );
                }).number += type.number;
            } else {
                newResources.push(type);
            }
            store.globalSessions.data.resources = newResources.filter(
                (transporter) => {
                    return transporter.number > 0;
                }
            );
        },
        removeConfigFromPotentialResources(type) {
            const cleanType = { ...type };
            delete cleanType._id;
            store.globalSessions.data.resources =
                store.globalSessions.data.resources.filter(
                    (resource) =>
                        JSON.stringify(resource) !== JSON.stringify(cleanType)
                );
        },
        updateRules(selectedRules) {
            const currentRules = store.globalSessions.data.rules;

            const newRules = currentRules.map((rule) => ({
                ...rule,
                enabled: selectedRules.some(
                    (r) => r.ruleName === rule.ruleName
                ),
            }));
            selectedRules.forEach((rule) => {
                // TODO: looks like prevention for not real use-case
                if (!currentRules.some((r) => r.ruleName === rule.ruleName)) {
                    newRules.push({
                        ...rule,
                        enabled: true,
                    });
                }
            });

            store.globalSessions.data.rules = [...newRules];
        },
        async getGlobalResources() {
            const resources = await apiClient.getResources();
            store.globalSessions.data.resources = resources?.data;
        },
        async getWorkbenchOrders() {
            const report = await apiClient.getOrders();

            // TODO - in flo-1765 refactor all places that use orders, correct filtering and grouping orders
            const hydratedOrders = await apiClient.getHydratedOrders();
            const hydratedOrdersCount = (hydratedOrders?.data || []).length;
            store.globalSessions.data.hydratedOrders =
                hydratedOrders?.data || [];
            store.globalSessions.data.hydratedOrdersCount = hydratedOrdersCount;
            store.globalSessions.data.orders = report?.data;
            store.globalSessions.data.filteredOrders = report?.data;
            const groupedByCompounds = _.chain(report?.data)
                .groupBy('from')
                .map((value, key) => ({ compound: key, orders: value }))
                .value();

            const groupedOrders = groupedByCompounds.map((c) => {
                const groupedByZones = _.chain(c.orders)
                    .groupBy('zone')
                    .map((value, key) => ({ zone: key, zones: value }))
                    .value();

                const zones = groupedByZones.map((zone) => {
                    const zoneOrders = zone.zones.map((o) => {
                        return {
                            id: o.id,
                            from: o.from,
                            to: o.to,
                            vin: o.vin,
                            city: o.city,
                            name: o.name,
                            priority: o.priority,
                            postCode: o.postCode,
                        };
                    });
                    return {
                        zone: zone.zone,
                        orders: zoneOrders,
                        order_number: zoneOrders.length,
                    };
                });

                return {
                    compound: c.compound,
                    zones: _.orderBy(zones, ['zone'], ['asc']),
                    order_number: c.orders.length,
                };
            });
            const sortedOrders = _.orderBy(
                groupedOrders,
                ['order_number'],
                ['desc']
            );
            store.globalSessions.data.zones = sortedOrders;
        },
        async getTransporterTypes() {
            const types = await apiClient.getTransporterTypes();
            store.globalSessions.data.types = types?.data;
        },
        async getLiveTransporters() {
            const types = await apiClient.getLiveTransporters();
            store.globalSessions.data.liveTransporters = types?.data;
        },
        async getPlaces() {
            const places = await apiClient.getPlaces();
            store.globalSessions.data.places = places?.data;
        },
        async getDataQualityReport() {
            const report = await apiClient.getDataQualityReport();
            store.globalSessions.data.report = report?.data;
        },
        async getSystemDQRules() {
            const rules = await apiClient.getSystemDQRules();
            const parsed = JSON.parse(rules?.data.value);
            const filterRules = parsed.map((element) => {
                return {
                    ruleName: element.ruleName,
                    payload: null,
                    enabled: false,
                };
            });
            const filteredRules = ['FILTER_BY_ORDER', 'FILTER_BY_ZONES'];
            store.globalSessions.data.rules = parsed.filter(
                (rule) => !filteredRules.includes(rule.ruleName)
            );
            store.globalSessions.data.filteredRules = filterRules.filter(
                (rule) => filteredRules.includes(rule.ruleName)
            );
        },
        init() {
            return Promise.all([
                store.globalSessions.getWorkbenchOrders(),
                store.globalSessions.getGlobalResources(),
                store.globalSessions.getLiveTransporters(),
                store.globalSessions.getTransporterTypes(),
                store.globalSessions.getPlaces(),
                store.globalSessions.getDataQualityReport(),
                store.globalSessions.getSystemDQRules(),
                store.refreshSessions(),
            ]);
        },
    };

    const refreshSessions = async () => {
        if (store.sessionsLoading) return;
        store.sessionsLoading = true;
        try {
            const response = await apiClient.getSolverSessions();
            store.sessions = response?.data;
            store.sessionsLoading = false;
        } catch (err) {
            store.sessionsLoading = false;
            // eslint-disable-next-line no-console
            console.error(err);
        }
    };
    const createSession = async (
        description,
        resources,
        runIterationOnCreate,
        runWithEfficiency,
        startedBy
    ) => {
        const id = uuidv4();
        let sessionDescription;
        if (description === '') {
            sessionDescription = `${DateTime.now().toFormat(
                'dd LLL yyyy'
            )} UNKNOWN`;
        } else {
            sessionDescription = description;
        }
        const compoundResources = [];

        // eslint-disable-next-line no-param-reassign
        resources?.map((el) => {
            const resource = compoundResources.find(
                (c) =>
                    c.placeCode === el.placeCode && c.fleetName === el.fleetName
            );

            if (resource) {
                resource.placeTimeConstraints.push({
                    startTime: el.startTime,
                    startLocation: el.startLocationCode,
                    startLocationName: el.startLocationName,
                    endTime: el.endTime,
                    endLocation: el.endLocationCode,
                    endLocationName: el.endLocationName,
                    value: el.number,
                });
                resource.number += el.number;
            } else {
                compoundResources.push({
                    placeCode: el.placeCode,
                    placeName: el.placeName,
                    fleetName: el.fleetName,
                    number: el.number,
                    placeTimeConstraints: [
                        {
                            startTime: el.startTime,
                            startLocation: el.startLocationCode,
                            startLocationName: el.startLocationName,
                            endTime: el.endTime,
                            endLocation: el.endLocationCode,
                            endLocationName: el.endLocationName,
                            value: el.number,
                        },
                    ],
                });
            }
            return compoundResources;
        });

        let fixedDate;
        if (runWithEfficiency) {
            fixedDate = `${DateTime.now().toFormat('yyyy-LL-dd')}`;
        } else {
            fixedDate = null;
        }

        const updatedRules = store.globalSessions.data?.rules?.concat(
            store.globalSessions.data.filteredRules
        );

        const sessionSetup = {
            parameterCollectionID: 1,
            sessionDescription,
            resources: compoundResources,
            rules: updatedRules || [],
            runIterationOnCreate,
            fixedDate,
            startedBy,
        };

        try {
            const sessionID = await apiClient.createSolverSession(
                id,
                sessionSetup
            );

            store.currentSession.sessionID = sessionID?.data?.session_id;
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        } finally {
            await store.refreshSessions();
            await store.globalSessions.clear();
        }
    };
    const deleteSession = async (id) => {
        try {
            await apiClient.deleteSession(id);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        } finally {
            await store.refreshSessions();
        }
    };
    const selectSession = async (id) => {
        await currentSession.clear();
        store.currentSession.sessionID = id;
    };

    return {
        refreshSessions,
        createSession,
        deleteSession,
        selectSession,
        currentSession,
        globalSessions,
    };
};
