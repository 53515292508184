export type Primitive = string | number | boolean | symbol | null | undefined;

export type PrimitiveWithObject = Primitive | { [k: string]: Primitive };

export type PrimitiveToKeyFunc = (value: Primitive) => string;

export type ReactKeyGenProps = {
    keyBaseName?: string;
    primitiveToKey?: (value: Primitive) => string;
};

const isPrimitive = (value: unknown): value is Primitive =>
    !value || !['object', 'function'].includes(typeof value);

const defaultPrimitiveToKey = (value: Primitive): string =>
    value?.toString ? value.toString() : String(value);

function ReactKeyGen<T extends PrimitiveWithObject>({
    keyBaseName = 'floKeyGen_',
    primitiveToKey = defaultPrimitiveToKey,
}: ReactKeyGenProps = {}) {
    const keysMap = new WeakMap<object, string>();

    const getUniqueKey = (() => {
        let cpt = -1;

        return () => {
            cpt += 1;
            return `${keyBaseName}${cpt}`;
        };
    })();

    return {
        getKey(value: T): string {
            if (isPrimitive(value)) {
                return primitiveToKey(value);
            }

            const key = keysMap.get(value);

            if (key) {
                return key;
            }

            const newKey = getUniqueKey();
            keysMap.set(value, newKey);

            return newKey;
        },
    };
}

export default ReactKeyGen;
