import { useApiContext } from '../apiclient';

export default ({ store }) => {
    const apiClient = useApiContext();
    const userSettings = {
        clear: async () => {
            store.userSettings.data = {
                groupings: [],
                group: {
                    selected: {},
                },
                compound: {
                    value: {},
                },
                groups: [],
                activeGroup: {
                    selected: undefined,
                },
            };
        },
        async selectSavedGroup(value, places) {
            if (value === '') {
                store.userSettings.data.activeGroup = {
                    selected: '',
                };
                store.userSettings.data.compound = {
                    value: '',
                };
                store.userSettings.data.group.selected.label = '';
                store.userSettings.data.group.selected.value = '';
                store.userSettings.data.groups = '';
            } else {
                store.userSettings.data.activeGroup.selected = value;
                store.userSettings.data.group.selected.label = value.label;
                store.userSettings.data.group.selected.value = value.name;

                const groups = value.group.filter(
                    (g) => g.field === 'places.postcode'
                )[0];
                store.userSettings.data.groups = groups.values.join('\n');
                const startCompound = value.group.filter(
                    (g) => g.field === 'orders.fromCode'
                )[0];
                const filteredPlace = places.filter((p) =>
                    startCompound.values.includes(p.code)
                )[0];
                store.userSettings.data.compound.value = filteredPlace;
            }
        },
        selectGroupName(groupName) {
            store.userSettings.data.group.selected.label = groupName;
            store.userSettings.data.group.selected.value = groupName;
        },
        selectUserGroup(value) {
            store.userSettings.data.groups = value;
        },
        selectCompoundValue(value) {
            if (value === '') {
                store.userSettings.data.compound = {};
            } else {
                store.userSettings.data.compound.value = value;
            }
        },
        resetAllGroupingValues() {
            store.userSettings.selectSavedGroup('');
            store.userSettings.selectGroupName('');
            store.userSettings.selectUserGroup([]);
        },
        async getSystemUserGroupings() {
            const groupings = await apiClient.getSystemUserGroupings();
            const userGroupings = JSON.parse(groupings?.data.value);
            const groups = userGroupings.map((element) => {
                return {
                    label: element.label,
                    value: element.value,
                    group: element.group,
                };
            });
            store.userSettings.data.groupings = groups;
        },
        async saveUserGroup({ groupName, placeGroupings, placeCode }) {
            const addToUserGroups = {
                label: groupName,
                value: groupName,
                group: [
                    {
                        field: 'places.postcode',
                        operator: 'IN',
                        values: placeGroupings,
                    },
                    {
                        field: 'orders.fromCode',
                        operator: 'IN',
                        values: [placeCode],
                    },
                ],
            };
            const newGroups = [addToUserGroups];
            const userGroupings = store.userSettings.data.groupings;
            const groups = userGroupings.map((element) => {
                return {
                    label: element.label,
                    value: element.value,
                    group: element.group,
                };
            });

            const toSave = groups.concat(newGroups);

            try {
                await apiClient.putSystemUserGroupings({
                    value: JSON.stringify(toSave),
                });
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await store.userSettings.resetAllGroupingValues();
                await store.userSettings.init();
            }
        },
        async updateUserGroup({ groupName, placeGroupings, placeCode }) {
            const addToUserGroups = {
                label: groupName,
                value: groupName,
                group: [
                    {
                        field: 'places.postcode',
                        operator: 'IN',
                        values: placeGroupings,
                    },
                    {
                        field: 'orders.fromCode',
                        operator: 'IN',
                        values: [placeCode],
                    },
                ],
            };
            const newGroups = [addToUserGroups];
            const existingGroups = store.userSettings.data.groupings.filter(
                (g) => g.value !== groupName
            );
            const groups = existingGroups.map((element) => {
                return {
                    label: element.label,
                    value: element.value,
                    group: element.group,
                };
            });

            const toSave = groups.concat(newGroups);
            try {
                await apiClient.putSystemUserGroupings({
                    value: JSON.stringify(toSave),
                });
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await store.userSettings.getSystemUserGroupings();
            }
        },
        async deleteUserGroup(group) {
            const newGroups = store.userSettings.data.groupings.filter(
                (g) => g.label !== group.label && g.value !== group.value
            );
            try {
                await apiClient.putSystemUserGroupings({
                    value: JSON.stringify(newGroups),
                });
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                await store.userSettings.getSystemUserGroupings();
            }
        },
        async init() {
            await store.userSettings.resetAllGroupingValues();
            await store.userSettings.getSystemUserGroupings();
        },
    };

    return {
        userSettings,
    };
};
