import { useLocalObservable } from 'mobx-react-lite';
import { useLocalStorage } from '@hooks/index';

export default () => {
    const [sidebarStorageShow, setSidebarStorageShow] = useLocalStorage(
        'sidebarShow',
        true
    );
    const store = useLocalObservable(() => ({
        sidebarShow: sidebarStorageShow,
        changeSidebarState: (val) => {
            store.sidebarShow = val;
            setSidebarStorageShow(val);
        },
        screenLoading: false,
    }));
    return store;
};
