import { makeAutoObservable, configure } from 'mobx';
import sessionFactory from './sessionFactory';

configure({
    enforceActions: 'never',
});

export default () => {
    const store = {
        sessions: [],
        sessionsLoading: false,
        sessionCreateFailed: false,
        currentSession: {
            interval: undefined,
            lastUpdate: {
                global: null,
                session_orders: null,
                session_solver_iterations: null,
                session_solver_proposed_loads: null,
                session_transporters: null,
                session_solver_sessions: null,
                session_solver_load_orders: null,
            },
            sessionID: null,
            loading: {
                session_orders: false,
                session_solver_iterations: false,
                session_solver_proposed_loads: false,
                session_transporters: false,
            },
            data: {
                sessionState: null,
                loads: [],
                orders: [],
                vehicles: [],
                places: [],
                transporters: [],
                transporterTypes: [],
                legislations: [],
                defaultTransporter: {},
                defaultLegislation: {},
                sessionReport: [],
            },
            activeLoad: {
                load: null,
                alternativeTruckCheck: {
                    status: 'NONE',
                    expertLoad: '',
                    start: {
                        options: [],
                        selected: {},
                    },
                    transporter: {
                        selected: {},
                    },
                    legislation: {
                        selected: {},
                    },
                },
            },
            manualLoad: false,
            manualLoadID: null,
            compoundResources: [],
            filteredOrders: [],
            tabKey: 'all',
            filteredLoads: [],
            activeFilters: [],
            manualOrders: [],
            metrics: [],
            multiIteration: false,
            pickIterationModal: false,
            loadMapOverlay: false,
        },
        globalSessions: {
            data: {
                places: [],
                types: [],
                report: {},
                resources: [],
                rules: [],
                filteredRules: [],
                zones: [],
                orders: [],
                filteredOrders: [],
                liveTransporters: [],
            },
            activeFilter: 'none',
            activeFilters: [],
        },
    };

    const factory = sessionFactory({ store });
    const {
        refreshSessions,
        deleteSession,
        selectSession,
        createSession,
        currentSession,
        globalSessions,
    } = factory;

    Object.assign(store, {
        refreshSessions,
        deleteSession,
        selectSession,
        createSession,
        currentSession: Object.assign(currentSession, store.currentSession),
        globalSessions: Object.assign(globalSessions, store.globalSessions),
    });
    return makeAutoObservable(store);
};
