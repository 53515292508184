const devRoleName = 'DEV';

const hasRealAccess = (keycloak, roleName) => {
    const realmAccess = keycloak?.realmAccess?.roles || [];
    return realmAccess.findIndex((role) => role === roleName) !== -1;
};

const featureFlags = (keycloak) => {
    return [
        {
            name: 'continueSolverButton',
            isActive: hasRealAccess(keycloak, devRoleName),
        },
        {
            name: 'runSolverEfficiencyMode',
            isActive: hasRealAccess(keycloak, devRoleName),
        },
        // TODO: as we don't have other role than dev, this should serve the purpose
        {
            name: 'genericDevFlag',
            isActive: hasRealAccess(keycloak, devRoleName),
        },
    ];
};

export default featureFlags;
