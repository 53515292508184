import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import LogRocket from 'logrocket';
import { AppConfig } from '@components/config';
import security from './security';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse" />
    </div>
);

// Containers
const TheLayout = React.lazy(() => import('../containers/layout/TheLayout'));
const LoginPage = React.lazy(() => import('./views/pages/Login'));

function App() {
    if (security.enabled) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { keycloak, initialized } = useKeycloak();
        if (!initialized) {
            return loading;
        }
        if (keycloak?.authenticated) {
            LogRocket.init('zwvdyf/flo');
            LogRocket.identify(keycloak.idTokenParsed.email, {
                name: keycloak.idTokenParsed.name,
                email: keycloak.idTokenParsed.email,

                // new env should be introduced with env url, this will do for debugging
                env: AppConfig.apiGateway.URL,
            });
        }
    } else {
        console.warn('Security Disabled');
    }

    const router = createBrowserRouter([
        {
            id: 'login',
            path: '/login',
            element: <LoginPage />,
        },
        {
            id: 'layout',
            path: '*',
            name: 'Home',
            element: <TheLayout />,
        },
    ]);

    return (
        <React.Suspense fallback={loading}>
            <RouterProvider router={router} />
        </React.Suspense>
    );
}

export default App;
