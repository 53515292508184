import { useCallback, useEffect } from 'react';

const useCallbackByKeyEvent = (key: string, callback: () => void) => {
    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === key) {
                callback();
            }
        },
        [key, callback]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
};

export default useCallbackByKeyEvent;
