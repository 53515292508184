import { useEffect, useCallback, useState } from 'react';
// eslint-disable-next-line import/no-duplicates
import axios from 'axios';
// eslint-disable-next-line import/no-duplicates
import AxiosInstance from 'axios';
import axiosRetry from 'axios-retry';
import { useKeycloak } from '@react-keycloak/web';

import isNil from 'lodash/isNil';
import { AppConfig } from '@components/config';

// eslint-disable-next-line import/prefer-default-export
export const useAPIClient = () => {
    const { keycloak, initialized } = useKeycloak();
    const [kcToken, setKcToken] = useState(keycloak?.token || '');

    // TODO: needs tests fixes in webfrontend/test-utilities/index.js

    const baseURLParam = AppConfig.apiGateway.enabled
        ? AppConfig.apiGateway.URL + AppConfig.apiGateway.paramManagerPrefix
        : AppConfig.apiDirect.paramManager;

    const baseURLMetrics = AppConfig.apiGateway.enabled
        ? AppConfig.apiGateway.URL + AppConfig.apiGateway.metricsPrefix
        : AppConfig.apiDirect.metrics;

    useEffect(() => {
        if (keycloak?.token) {
            setKcToken(keycloak.token);
        }
    }, [keycloak.token]);

    const useInterceptor = (instance) => {
        instance.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                const originalRequest = error.config;
                // eslint-disable-next-line no-underscore-dangle
                if (
                    (error?.response?.status === 401 ||
                        error?.response?.status === 403) &&
                    // eslint-disable-next-line no-underscore-dangle
                    !originalRequest._retry
                ) {
                    // eslint-disable-next-line no-console
                    console.warn('401 detected, refreshing instance');
                    // eslint-disable-next-line no-underscore-dangle
                    originalRequest._retry = true;
                    keycloak
                        .updateToken(5)
                        .then((refreshed) => {
                            if (refreshed) {
                                setKcToken(keycloak.token);
                                instance.interceptors.request.use((config) => {
                                    return {
                                        ...config,
                                        headers: {
                                            ...config.headers,
                                            Authorization: kcToken
                                                ? `Bearer ${kcToken}`
                                                : '',
                                        },
                                    };
                                });
                                // eslint-disable-next-line no-console
                                console.info(
                                    'Token was successfully refreshed'
                                );
                            } else {
                                // eslint-disable-next-line no-console
                                console.warn(
                                    'Token is still valid, resetting state.'
                                );
                                setKcToken(keycloak.token);
                            }
                        })
                        .catch(() => {
                            // eslint-disable-next-line no-console
                            console.warn(
                                'Failed to refresh the token, or the session has expired'
                            );
                        });
                    return instance(originalRequest);
                }
                return Promise.reject(error);
            }
        );
    };

    useEffect(() => {
        AxiosInstance.param = axios.create({
            baseURL: baseURLParam,
            headers: {
                Authorization: initialized ? `Bearer ${kcToken}` : undefined,
            },
        });
        if (AxiosInstance.param) {
            axiosRetry(AxiosInstance.param, { retries: 3 });
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useInterceptor(AxiosInstance.param);
        }
        AxiosInstance.metrics = axios.create({
            baseURL: baseURLMetrics,
            headers: {
                Authorization: initialized ? `Bearer ${kcToken}` : undefined,
            },
        });
        if (AxiosInstance.metrics) {
            axiosRetry(AxiosInstance.metrics, { retries: 3 });
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useInterceptor(AxiosInstance.metrics);
        }
        return () => {
            AxiosInstance.param = undefined;
            AxiosInstance.metrics = undefined;
        };
    }, [kcToken]);

    const getSolverDataset = useCallback(
        (uri) => {
            return !!AxiosInstance.param && AxiosInstance.param.get(uri);
        },
        [AxiosInstance]
    );

    const getCSVCollection = useCallback(
        (collection) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/params/${collection}/csv`)
            );
        },
        [AxiosInstance]
    );

    const getParamJSONCollection = useCallback(
        (collection) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/params/${collection}/param`)
            );
        },
        [AxiosInstance]
    );

    const postCSVCollection = useCallback(
        (collection, params) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.post(`/params/${collection}/csv`, params)
            );
        },
        [AxiosInstance]
    );

    const postParamJSONCollection = useCallback(
        (collection, params) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.post(`/params/${collection}/param`, params)
            );
        },
        [AxiosInstance]
    );

    const postStaticContent = useCallback(
        (collection, params) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.post(`/input/${collection}/static`, params)
            );
        },
        [AxiosInstance]
    );

    const postDynamicContent = useCallback(
        (collection, params) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.post(`/input/${collection}/dynamic`, params)
            );
        },
        [AxiosInstance]
    );

    const getDataQualityReport = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param
                .get(`/workbench/qualityReport`)
                .then((response) => {
                    return response.data;
                })
        );
    }, [AxiosInstance]);

    // Solver Sessions
    const getSolverSessions = useCallback(() => {
        return !!AxiosInstance.param && AxiosInstance.param.get(`/sessions`);
    }, [AxiosInstance]);

    const createSolverSession = useCallback(
        (sessionID, sessionSetup) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.post(`/session/${sessionID}`, sessionSetup)
            );
        },
        [AxiosInstance]
    );

    const deleteSession = useCallback(
        (sessionID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.delete(`/session/${sessionID}`)
            );
        },
        [AxiosInstance]
    );

    const getSolverSessionState = useCallback(
        (sessionID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/session/${sessionID}`)
            );
        },
        [AxiosInstance]
    );

    // Solver Session
    const startSolverRun = useCallback(
        (sessionID, continueSolver, multiIterations) => {
            const query = isNil(continueSolver)
                ? `?multi=${multiIterations}`
                : `?continue=${continueSolver}&multi=${multiIterations}`;
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.post(
                    `/session/${sessionID}/iteration${query}`
                )
            );
        },
        [AxiosInstance]
    );

    const getIteration = useCallback(
        (iterationUID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/iteration/${iterationUID}`)
            );
        },
        [AxiosInstance]
    );

    const getIterationStatus = useCallback(
        (iterationUID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.patch(`/iteration/${iterationUID}/results`)
            );
        },
        [AxiosInstance]
    );

    // Get orders, load & resources
    const getOrdersForNVD = useCallback(
        ({ email }) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/workbench/triggerSync?email=${email}`)
            );
        },
        [AxiosInstance]
    );

    const getLoadMapView = useCallback((sessionID, loadID) => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(`/loads/${sessionID}/getMapView/${loadID}`)
        );
    }, []);

    const getSessionMapData = useCallback((sessionID) => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(`/session/${sessionID}/mapData`)
        );
    }, []);

    const getSessionOrders = useCallback(
        (sessionID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/session/${sessionID}/orders`)
            );
        },
        [AxiosInstance]
    );

    const getSessionVehicles = useCallback(
        (sessionID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/session/${sessionID}/vehicles`)
            );
        },
        [AxiosInstance]
    );

    const getSessionPlaces = useCallback(
        (sessionID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/session/${sessionID}/places`)
            );
        },
        [AxiosInstance]
    );

    const getSessionTransporters = useCallback(
        (sessionID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/transporters/${sessionID}`)
            );
        },
        [AxiosInstance]
    );

    const getSessionLoads = useCallback(
        (sessionID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/loads/${sessionID}`)
            );
        },
        [AxiosInstance]
    );

    const getSessionReport = useCallback(
        (sessionID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/session/${sessionID}/report`)
            );
        },
        [AxiosInstance]
    );

    const getOrderDetails = useCallback(
        (orderID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/orders/${orderID}`)
            );
        },
        [AxiosInstance]
    );

    const getLoadDetails = useCallback(
        (loadID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/loads/${loadID}`)
            );
        },
        [AxiosInstance]
    );

    const getLegislations = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(`/fixtures/globalData/legislation.json`)
        );
    }, [AxiosInstance]);

    const getCountries = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(`/fixtures/globalData/country.json`)
        );
    }, [AxiosInstance]);

    // Modification
    const modifyLoadAssociation = useCallback(
        // TODO: verify if it is used at all
        (orderID, operations) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.patch(
                    `/order/${orderID}/loadAssociations`,
                    operations
                )
            );
        },
        [AxiosInstance]
    );

    const modifyLoadAssociationForMultipleOrders = useCallback(
        (loadID, operations) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.patch(
                    `/orders/${loadID}/loadAssociations`,
                    operations
                )
            );
        },
        [AxiosInstance]
    );

    const setLoadStatus = useCallback(
        (loadID, status) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.patch(`/load/${loadID}/status`, {
                    status,
                })
            );
        },
        [AxiosInstance]
    );

    const createManualLoad = useCallback(
        // TODO: move it to api Calls
        (loadID, loadSetup) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.post(`/load/${loadID}`, loadSetup)
            );
        },
        [AxiosInstance]
    );

    const removeLoad = useCallback(
        // TODO: move it to api calls
        (loadID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.delete(`/load/${loadID}`)
            );
        },
        [AxiosInstance]
    );
    const getSyncStatus = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(`/workbench/lastStatus`)
        );
    }, [AxiosInstance]);
    const getSessionLastUpdate = useCallback(
        (sessionID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param
                    .get(`/session/${sessionID}/lastUpdate`)
                    .then((response) => {
                        return response.data;
                    })
            );
        },
        [AxiosInstance]
    );
    const saveSession = useCallback(
        (sessionID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.post(`/session/${sessionID}/save`)
            );
        },
        [AxiosInstance]
    );

    const getMetrics = useCallback(
        (sessionID) => {
            return (
                !!AxiosInstance.metrics &&
                AxiosInstance.metrics.get(`/metrics/${sessionID}`)
            );
        },
        [AxiosInstance]
    );

    const getLoadMetrics = useCallback(
        (sessionID) => {
            return (
                !!AxiosInstance.metrics &&
                AxiosInstance.metrics.get(`/metrics/loads/${sessionID}`)
            );
        },
        [AxiosInstance]
    );

    const getIterationMetrics = useCallback(
        (iterationID) => {
            return (
                !!AxiosInstance.metrics &&
                AxiosInstance.metrics.get(
                    `/metrics/sessioniteration/${iterationID}`
                )
            );
        },
        [AxiosInstance]
    );

    const getMultiSessionIterationMetrics = useCallback(() => {
        return (
            !!AxiosInstance.metrics &&
            AxiosInstance.metrics.get(
                `/metrics/multisessioniterationmetrics/multi`
            )
        );
    }, [AxiosInstance]);

    const getSessionsWithMetrics = useCallback(() => {
        return !!AxiosInstance.metrics && AxiosInstance.metrics.get(`/metrics`);
    }, [AxiosInstance]);

    const postManualMetrics = useCallback(
        (id, metrics) => {
            return (
                !!AxiosInstance.metrics &&
                AxiosInstance.metrics.post(`/input/${id}`, metrics)
            );
        },
        [AxiosInstance]
    );

    const getUnknownLoadConfigurations = useCallback(
        (status) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.get(`/session/loads/${status}`)
            );
        },
        [AxiosInstance]
    );

    const updateTransporterConfigProposal = useCallback(
        (loadID, configStatus, approver) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.put(
                    `/load/${loadID}/configValidity/${configStatus}`,
                    {
                        approver,
                    }
                )
            );
        },
        []
    );

    const updateRiskyConfigStatus = useCallback(
        ({ approver, transporterType, legislation, models, status }) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.put(`/config`, {
                    approver,
                    transporterType,
                    legislation,
                    models,
                    status,
                })
            );
        }
    );

    const updateLoadConfigurationStatus = useCallback(
        (loadID, status) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.post(
                    `/session/load/${loadID}/update/${status}`
                )
            );
        },
        [AxiosInstance]
    );

    const getGlobalConfigs = useCallback(
        (status) => {
            let url = `/workbench/configs`;
            if (status) {
                url += `?status=${status}`;
            }
            return !!AxiosInstance.param && AxiosInstance.param.get(url);
        },
        [AxiosInstance]
    );

    const getPlaces = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(`/workbench/places`)
        );
    }, [AxiosInstance]);

    const getLiveTransporters = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(`/workbench/liveTransporters`)
        );
    }, [AxiosInstance]);

    const getTransporterTypes = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(`/workbench/transporterTypes`)
        );
    }, [AxiosInstance]);

    const getResources = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(`/workbench/transporters`)
        );
    }, [AxiosInstance]);

    const checkLoadConfigFitsTruck = useCallback(
        (loadID, type) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.post(`/load/${loadID}/validity`, type)
            );
        },
        [AxiosInstance]
    );

    const updateLoadTransporter = useCallback(
        (loadID, details) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.patch(
                    `/load/${loadID}/transporter`,
                    details
                )
            );
        },
        [AxiosInstance]
    );

    const getSystemDQRules = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(
                `/_system_configuration/dq_rules_configuration`
            )
        );
    }, [AxiosInstance]);

    const getSystemDefaultValues = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(
                `/_system_configuration/default_values_configuration`
            )
        );
    }, [AxiosInstance]);

    const getSystemUserGroupings = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(
                `/_system_configuration/user_group_configuration`
            )
        );
    }, [AxiosInstance]);

    const putSystemUserGroupings = useCallback(
        (groupings) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.put(
                    `/_system_configuration/user_group_configuration`,
                    groupings
                )
            );
        },
        [AxiosInstance]
    );

    const setHighPriority = useCallback(
        (orderID, priorityLevel) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.patch(
                    `/order/${orderID}/priority/${priorityLevel}`
                )
            );
        },
        [AxiosInstance]
    );

    const flipLoadSellFlag = useCallback(
        (loadID) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.post(`/load/${loadID}/sell`)
            );
        },
        [AxiosInstance]
    );

    const getOrders = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(`/workbench/orders`, {
                params: { valid: true },
            })
        );
    }, [AxiosInstance]);

    const getHydratedOrders = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.get(`/workbench/hydrated/orders`, {
                params: { valid: true },
            })
        );
    }, [AxiosInstance]);

    const pickIteration = useCallback(
        (sessionID, iterationsList) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.post(
                    `/session/${sessionID}/pickIteration`,
                    iterationsList
                )
            );
        },
        [AxiosInstance]
    );

    const heartbeat = useCallback(() => {
        return (
            !!AxiosInstance.param &&
            AxiosInstance.param.head(`/_alive`, null, { timeout: 1000 })
        );
    }, [AxiosInstance]);

    const updateLoad = useCallback(
        (loadID, data) => {
            return (
                !!AxiosInstance.param &&
                AxiosInstance.param.patch(`/load/${loadID}`, data)
            );
        },
        [AxiosInstance]
    );

    return {
        getSolverDataset,
        postCSVCollection,
        postParamJSONCollection,
        getParamJSONCollection,
        postStaticContent,
        postDynamicContent,
        getCSVCollection,
        getDataQualityReport,
        getSolverSessions,
        getSolverSessionState,
        createSolverSession,
        deleteSession,
        startSolverRun,
        getSessionOrders,
        getSessionVehicles,
        getSessionPlaces,
        getSessionTransporters,
        getTransporterTypes,
        getLiveTransporters,
        getLoadMapView,
        getSessionMapData,
        getSessionLoads,
        createManualLoad,
        removeLoad,
        getOrderDetails,
        getLoadDetails,
        setLoadStatus,
        modifyLoadAssociation,
        modifyLoadAssociationForMultipleOrders,
        getIterationStatus,
        getIteration,
        getOrdersForNVD,
        getSyncStatus,
        getSessionLastUpdate,
        saveSession,
        getMetrics,
        getSessionsWithMetrics,
        postManualMetrics,
        getUnknownLoadConfigurations,
        updateLoadConfigurationStatus,
        getCountries,
        getLegislations,
        getGlobalConfigs,
        getPlaces,
        getResources,
        checkLoadConfigFitsTruck,
        getSystemDQRules,
        getSystemDefaultValues,
        getSystemUserGroupings,
        putSystemUserGroupings,
        updateLoadTransporter,
        setHighPriority,
        flipLoadSellFlag,
        getLoadMetrics,
        getIterationMetrics,
        getOrders,
        getHydratedOrders,
        updateTransporterConfigProposal,
        updateRiskyConfigStatus,
        pickIteration,
        getMultiSessionIterationMetrics,
        getSessionReport,
        AxiosInstance,
        heartbeat,
        kcToken,
        updateLoad,
    };
};
