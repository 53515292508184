import React, { createContext } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import useUiStore from './uiStore';
import useDataSync from './dataSyncStore';
import useConfigStore from './loadConfigurationStore';
import useSessionStore from './sessionStore';
import useUserStore from './userStore';

export const useAppStore = () => {
    const uiStore = useUiStore();
    const dataSync = useDataSync();
    const configStore = useConfigStore();
    const sessionStore = useSessionStore();
    const userStore = useUserStore();
    const appStore = useLocalObservable(() => ({
        ui: uiStore,
        dataSync,
        configStore,
        sessionStore,
        userStore,
    }));
    return appStore;
};
export const AppContext = createContext(undefined);
export default observer(({ children }) => {
    const appStore = useAppStore();
    return (
        <AppContext.Provider value={appStore}>{children}</AppContext.Provider>
    );
});

export const useAppContext = () => {
    const context = React.useContext(AppContext);
    if (context === undefined) {
        throw new Error(
            'useAppContext must be used within a AppContextProvider'
        );
    }
    return context;
};
